import { APIService } from "@/network/api/APIService";

export default class MethodWrapper extends APIService {
  methodName: string;
  httpMethod: string;
  URL: string;

  constructor(methodName: string, httpMethod: string, URL: string) {
    super();
    this.methodName = methodName;
    this.httpMethod = httpMethod;
    this.URL = URL;
  }

  createFunction() {
    const HTTP_METHOD = this.httpMethod;
    switch (HTTP_METHOD) {
      case "GET":
        return new Function(
          "params=null",
          `return this.get(this.URL,params)`
        ).bind(this);

      case "PUT":
        return new Function("data", `return this.put(this.URL, data)`).bind(
          this
        );

      case "POST":
        return new Function("data", `return this.post(this.URL, data)`).bind(
          this
        );

      case "DELETE":
        return new Function(
          "params=null",
          `return this.delete(this.URL, params)`
        ).bind(this);
    }
  }
}
