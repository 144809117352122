import { render, staticRenderFns } from "./MyTasks.vue?vue&type=template&id=a44eb95e&scoped=true&"
import script from "./MyTasks.ts?vue&type=script&lang=ts&"
export * from "./MyTasks.ts?vue&type=script&lang=ts&"
import style0 from "./MyTasks.css?vue&type=style&index=0&id=a44eb95e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a44eb95e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VIcon,VSpacer,VToolbar,VToolbarTitle})
