import User from "@/helpers/presenters/User";
import { defineStore } from "pinia";
import Vue from "vue";
import axios from "axios";

const useUserStore = defineStore({
  id: "user",
  state: () => ({
    user: null as User | null,
    token: null as string | null,
  }),
  actions: {
    setUser(user: User) {
      this.user = user;
    },

    setToken(token: string | null) {
      //console.log("ENTERED SET TOKEN");
      if (!token) return;
      //console.log("TOKEN IS SET");
      axios.defaults.headers.common["Authorization"] = token;
    },

    getTokenFromCookies() {
      this.token = Vue.cookies.get("authtoken") as string | null;
    },

    deleteTokenFromCookies() {
      this.token = "";
      Vue.cookies.remove("authtoken");
    },
  },
});

export default useUserStore;
