import TaskBox from "@/components/TaskBox/TaskBox.vue";
import StopWatch from "@/components/StopWatch/StopWatch.vue";
import { defineComponent } from "@vue/composition-api";
import Task from "@/helpers/presenters/Task";

export default defineComponent({
  name: "MemberTasks",
  created() {
    const tasks = <Task[]>this.tasks;
    this.activeTask = tasks.filter((task) => task.isStarted());
    this.getFirstTaskTime();
  },
  data() {
    return {
      firstTaskTime: "",
      activeTask: [] as Task[],
    };
  },
  computed: {},
  methods: {
    getFirstTaskTime() {
      ////console.log(this.tasks)
      const tasks = <Task[]>this.tasks;
      const dates = tasks.map((task) => task.firstLog.start_date);
      ////console.log(dates)
      if (!dates.length) return 0;
      dates.sort();
      this.firstTaskTime = dates[0];
    },
    getTotalSpentTime() {
      const tasks = <Task[]>this.tasks;
      const taskTimes = tasks.map((task) => task.totalTime());
      if (!taskTimes.length) return 0;
      const totalTime = taskTimes.reduce((prev, curr) => prev + curr);
      return totalTime * 1000;
    },
    currentTime() {
      const current = new Date();
      current.setHours(current.getHours() + 3);
      return current.getTime();
    },
    allTasksPaused() {
      // "1" === 1
      const tasks = <Task[]>this.tasks;
      if (!tasks.length) return false;
      const noStartedTasks: boolean = tasks.every((task) => !task.isStarted());
      const atLeastOnePaused = tasks.some((task) => task.isPaused());
      return atLeastOnePaused && noStartedTasks;
    },
    getTotalBreakTime() {
      const tasks = <Task[]>this.tasks;
      if (!tasks.length) return 0;
      this.getFirstTaskTime();
      const timeSinceFirstTask = new Date(this.firstTaskTime).getTime();
      const totalBreak =
        new Date().getTime() - timeSinceFirstTask - this.getTotalSpentTime();
      return totalBreak;
    },
  },
  watch: {},
  components: {
    TaskBox,
    StopWatch,
  },
  props: {
    tasks: Array,
    name: String,
  },
});
