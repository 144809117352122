import StopWatch from "@/components/StopWatch/StopWatch.vue";
import useUserStore from "@/stores/userStore";
import TaskService from "@/network/api/TaskService";
//import TaskHelper from '@/other/TaskHelper'
import { defineComponent } from "@vue/composition-api";
import Task from "@/helpers/presenters/Task";
import JSONFetcher from "@/network/api/JSONFetcher";

export default defineComponent({
  name: "TaskBox",
  setup() {
    const userStore = useUserStore();
    return {
      userStore,
    };
  },
  async created() {
    // await JSONFetcher.fetchJSONFile();
    this.token = this.userStore.token;
  },
  data() {
    return {
      myTasks: null,
      token: null as string | object | null,
      notes: this.task.notes,
      pauseIsClicked: false,
      saveIsClicked: false,
      startIsClicked: false,
      deleteIsClicked: false,
      restartIsClicked: false,
    };
  },
  methods: {
    async saveTask() {
      this.saveIsClicked = true;
      const data = {
        id: this.task.id,
        notes: this.notes,
      };
      await (window as any).$network.edit_task(data);
      await this.getMyTasks();
      this.saveIsClicked = false;
      const text = "Changes have been saved.";
      this.$emit("display", text);
    },
    async getMyTasks() {
      await this.$emit("getMyTasks");
    },
    async pauseTask() {
      this.pauseIsClicked = true;
      const task = this.task;
      const data = {
        id: task.id,
      };
      await (window as any).$network.stop_task(data);
      await this.getMyTasks();
      this.pauseIsClicked = false;
      const text = "Task has been paused.";
      this.$emit("display", text);
    },
    async startTask() {
      this.startIsClicked = true;
      const data = {
        id: this.task.id,
      };
      await (window as any).$network.start_task(data);
      // this.$refs.StopWatch.start()
      await this.pauseActiveTask();
      await this.getMyTasks();
      this.startIsClicked = false;
      const text = "Task has been started.";
      this.$emit("display", text);
    },
    async pauseActiveTask() {
      if (this.activeTask === undefined) return;
      const activeTask = this.activeTask.length
        ? <Task>this.activeTask[0]
        : null;
      if (!activeTask) return;
      const data = {
        id: activeTask.id,
      };
      await (window as any).$network.stop_task(data);
    },
    async restartTask() {
      this.restartIsClicked = true;
      const data = {
        id: this.task.id,
      };
      await (window as any).$network.unend_task(data);
      // this.$refs.StopWatch.start()
      await this.getMyTasks();
      this.restartIsClicked = false;
      const text = "Task has been restarted.";
      this.$emit("display", text);
    },
    async deleteTask() {
      const data = {
        id: this.task.id,
      };
      this.deleteIsClicked = true;
      await (window as any).$network.delete_task(data);
      await this.getMyTasks();
      this.deleteIsClicked = true;
      const text = "Task has been deleted.";
      this.$emit("display", text);
    },
    currentTime() {
      const current = new Date();
      current.setHours(current.getHours() + 3);
      return current.getTime();
    },
    updateLogTime(task: Task) {
      if (task.lastLog.end_date !== null) return 0;
      return new Date().getTime() - new Date(task.lastLog.start_date).getTime();
    },
  },
  computed: {
    taskColor(): string {
      if (this.task.isFinished()) return "#edede9";
      return this.task.isStarted() ? "#86ddb6" : "rgba(225, 103, 116, 0.8)";
    },
    taskDate(): Date {
      return this.task.getDate();
    },
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    activeTask: {
      type: Array,
      required: false,
    },
    isMember: Boolean,
    name: String,
    tasksOpened: Boolean,
    oldTask: Boolean,
  },
  components: {
    StopWatch,
  },
});
