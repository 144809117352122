export default class NetworkService {
  constructor(wrappedMethods) {
    this.wrappedMethods = wrappedMethods;
    this.createMethods();
  }

  createMethods() {
    for (const wrappedMethod of this.wrappedMethods) {
      NetworkService.prototype[wrappedMethod.methodName] =
        wrappedMethod.createFunction();
    }
  }
}
