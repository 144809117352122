import router from "@/router/index";
import { defineComponent } from "@vue/composition-api";
import AuthenticationService from "@/network/api/AuthenticationService";
import "@/vendor/axios.ts";

export default defineComponent({
  name: "LoginPage",
  data() {
    return {
      email: "" as string,
      domain: "" as string,
      error: false as boolean,
    };
  },
  methods: {
    async onSubmit(): Promise<void> {
      const data = {
        email: this.email,
        domain: this.domain,
      };
      try {
        await (window as any).$network.signin(data);
        this.error = false;
        router.push("verify-login");
      } catch (error) {
        this.error = true;
      }
    },
    updateDomain(): void {
      this.error = false;
      const split: string[] = this.email.split("@");
      if (split.length === 2) {
        this.domain = split[1];
      }
    },
  },
  computed: {
    disabledButton(): boolean {
      return this.email === "";
    },
  },
});
