import { defineComponent } from "@vue/composition-api";
import TaskBox from "@/components/TaskBox/TaskBox.vue";
import StopWatch from "@/components/StopWatch/StopWatch.vue";
import useUserStore from "@/stores/userStore";
import TaskForm from "@/components/TaskForm/TaskForm.vue";
import Task from "@/helpers/presenters/Task";
export default defineComponent({
  name: "MyTasks",
  methods: {
    display(message: string) {
      this.$emit("display", message);
    },
    getMyTasks() {
      this.$emit("getMyTasks");
    },
  },
  props: {
    activeTask: Array,
    myTasks: Array,
  },
  components: {
    TaskBox,
    StopWatch,
    TaskForm,
  },
});
