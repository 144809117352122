/* eslint-disable @typescript-eslint/ban-types */
import useUserStore from "@/stores/userStore";
import axios from "axios";
import { createPinia, setActivePinia, Store } from "pinia";

const pinia = createPinia();
const SERVER_URL = "https://api.wired.sowlutions.com/api/v2";
axios.defaults.baseURL = SERVER_URL;
setActivePinia(pinia);
// ✅ works because the pinia instance is now active
type id = {
  id: number;
};
export class APIService {
  userStore: Store;
  constructor() {
    this.userStore = useUserStore(pinia);
  }

  wrap(data: object | null) {
    return {
      params: data,
    };
  }

  apiUrl(url: string, params: any = null) {
    if (!params) return url;
    const dynamicUrl = url.includes(":id");
    return dynamicUrl ? url.replace(":id", `${params.id}`) : url;
  }

  async get(url = "", params: any = null) {
    const response = await axios.get(
      this.apiUrl(url, params),
      this.wrap(params)
    );
    return response;
  }

  async put(url = "", data: any) {
    const response = await axios.put(this.apiUrl(url, data), data);
    return response;
  }

  async post(url = "", data: any) {
    const response = axios.post(this.apiUrl(url, data), data);
    return response;
  }

  async delete(url = "", params: any = null) {
    const response = axios.delete(this.apiUrl(url, params), this.wrap(params));
    return response;
  }
}
export const APIServiceInstance = new APIService();
