import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "SnackBar",
  data() {
    return {
      value: false,
    };
  },
  props: {
    snackbar: Boolean,
    text: String,
  },
  watch: {
    snackbar(newValue) {
      this.value = newValue;
    },
    value(newValue) {
      if (!newValue) this.$emit("resetSnackBar");
    },
  },
});
