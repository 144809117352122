import { APIService } from "@/network/api/APIService";
import Task from "@/helpers/presenters/Task";
import { TaskInterface } from "@/helpers/interfaces/TaskInterface";

export type id = {
  id: number;
};

class TaskService extends APIService {
  constructor() {
    super();
  }

  async getMyTasks(params: { limit: number; offset: number } | null = null) {
    const response = await this.get("/tasks", params);
    const tasks: TaskInterface[] = response.data.data.items;
    return tasks.map((task) => new Task(task)); //return array of Task objects
  }

  async createTask(data: object) {
    const response = await this.post("/tasks", data);
    return response;
  }

  async startTask(data: id) {
    return this.post(`/tasks/${data.id}/start`, data);
  }

  async pauseTask(data: id) {
    return this.post(`/tasks/${data.id}/stop`, data);
  }

  async editTask(data: id) {
    return this.put(`/tasks/${data.id}`, data);
  }

  async restartTask(data: id) {
    return this.put(`/tasks/${data.id}/unend`, data);
  }

  async endTask(data: id) {
    return this.put(`/tasks/${data.id}/end`, data);
  }

  async deleteTask(data: id) {
    return this.delete(`/tasks/${data.id}`, data);
  }
}

export default new TaskService();
