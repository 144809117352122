import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "StopWatch",
  created() {
    if (this.started) this.start();
    else this.stop();
  },
  mounted() {
    this.elapsedTime = this.lastLogTime;
  },
  data() {
    return {
      elapsedTime: this.lastLogTime as number,
      timer: 0 as number,
    };
  },
  computed: {
    formattedElapsedTime() {
      const date = new Date(0);
      if (this.elapsedTime < 0) this.initElapsedTime();
      date.setSeconds(this.elapsedTime / 1000);
      const utc = date.toUTCString();
      return utc.substr(utc.indexOf(":") - 2, 8);
    },
  },
  methods: {
    initElapsedTime() {
      this.elapsedTime = 0;
    },
    start() {
      this.timer = setInterval(() => {
        this.elapsedTime += 1000;
      }, 1000);
    },
    stop() {
      if (!this.total) this.elapsedTime = 0;
      clearInterval(this.timer);
    },
    reset() {
      this.elapsedTime = 0;
    },
  },
  watch: {
    started(newValue) {
      if (newValue) this.start();
      else this.stop();
    },
    lastLogTime(newValue) {
      this.elapsedTime = newValue;
    },
  },
  props: {
    lastLogTime: {
      type: Number,
      required: true,
    },
    started: {
      type: Boolean,
      required: true,
    },
    total: {
      type: Boolean,
      required: true,
    },
  },
});
