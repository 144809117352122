import axios from "axios";
import MethodWrapper from "@/network/api/MethodWrapper";

const MAIN_URL = "https://api.wired.sowlutions.com";

class JSONFetcher {
  wrapMethod(method) {
    const methodName = method.name;
    const httpMethod = method.apis[0].http_method;
    const url = method.apis[0].api_url.split("v2/")[1];
    return new MethodWrapper(methodName, httpMethod, url);
  }
  async fetchJSONFile() {
    const response = await axios.get(`${MAIN_URL}/apipie/1.0.en.json`);
    const jsonFile = response.data.docs;
    const endpoints = jsonFile.resources;
    const methods = [];

    for (let endpoint in endpoints) {
      methods.push(endpoints[endpoint].methods);
    }

    const flattened_methods = methods.flat(); //flatten array

    const wrappedMethods = flattened_methods.map((method) =>
      this.wrapMethod(method)
    );
    return wrappedMethods;
  }
}

export default new JSONFetcher();
