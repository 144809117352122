import ShortcutInterface from "@/helpers/interfaces/ShortcutInterface";

export default class Shortcut {
  id: number;
  project: string;
  category: string;
  constructor(shortcut: ShortcutInterface) {
    this.id = shortcut.id;
    this.project = shortcut.data.project_name;
    this.category = shortcut.data.category_name;
  }
  checkShortcut(project: string, category: string) {
    return this.project === project && this.category === category;
  }
}
