import CategoryInterface from "@/helpers/interfaces/CategoryInterface";

export default class Category {
  id: number;
  name: string;
  constructor(category: CategoryInterface) {
    this.id = category.id;
    this.name = category.name;
  }
}
