import useUserStore from "@/stores/userStore";
import { defineComponent } from "@vue/composition-api";
import AuthenticationService from "@/network/api/AuthenticationService";
import { Store } from "pinia";

export default defineComponent({
  name: "LoginPage",
  setup() {
    const userStore = useUserStore();
    return {
      userStore,
    };
  },
  data() {
    return {
      token: "" as string,
      error: false as boolean,
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.userStore.setToken(this.token);
        console.log((window as any).$network.get_user_info);
        await (window as any).$network.get_user_info();
        this.error = false;
        this.$cookies.set("authtoken", this.token);
        this.userStore.token = this.token;
        this.$router.push("/");
      } catch (error) {
        this.error = true;
        this.token = "";
      }
    },
    resetError(): void {
      this.error = false;
    },
  },
  computed: {
    disabledButton(): boolean {
      return this.token.length < 32;
    },
  },
});
