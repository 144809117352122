import Loader from "@/components/Loader.vue";
import useUserStore from "@/stores/userStore";
import TaskForm from "@/components/TaskForm/TaskForm.vue";
import MemberTasks from "@/components/MemberTasks/MemberTasks.vue";
import TaskBox from "@/components/TaskBox/TaskBox.vue";
import StopWatch from "@/components/StopWatch/StopWatch.vue";
import { defineComponent } from "@vue/composition-api";
import AppBar from "@/components/AppBar/AppBar.vue";
import SnackBar from "@/components/SnackBar/SnackBar.vue";
import SelectedTasks from "@/components/SelectedTasks/SelectedTasks.vue";
import ActiveMembers from "@/components/ActiveMembers/ActiveMembers.vue";
import MyTasks from "@/components/MyTasks/MyTasks.vue";
import ButtonsMenu from "@/components/ButtonsMenu.vue";
import ShortcutDialog from "@/components/ShortcutDialog.vue";
import TaskService from "@/network/api/TaskService";
import TeamService from "@/network/api/TeamService";
import AuthenticationService from "@/network/api/AuthenticationService";
import { minutesToMilliseconds } from "date-fns";
import getDay from "date-fns/getDay";
import Task from "@/helpers/presenters/Task";
import Project from "@/helpers/presenters/Project";
import Shortcut from "@/helpers/presenters/Shortcut";
import UserShortcutsService from "@/network/api/UserShortcutsService";
import getDayOfYear from "date-fns/getDayOfYear";
import endOfMonth from "date-fns/endOfMonth";
import Vue from "vue";
import ShortcutInterface from "@/helpers/interfaces/ShortcutInterface";
import { TaskInterface } from "@/helpers/interfaces/TaskInterface";
import NetworkService from "@/network/api/NetworkService";
import JSONFetcher from "@/network/api/JSONFetcher";
import User from "@/helpers/presenters/User";
import ProjectInterface from "@/helpers/interfaces/ProjectInterface";

const $network = NetworkService.prototype as any;

export default defineComponent({
  name: "MainPage",

  setup() {
    const userStore = useUserStore();
    return {
      userStore,
    };
  },
  components: {
    StopWatch,
    TaskBox,
    TaskForm,
    MemberTasks,
    SnackBar,
    AppBar,
    ActiveMembers,
    SelectedTasks,
    MyTasks,
    Loader,
    ButtonsMenu,
    ShortcutDialog,
  },

  data() {
    return {
      shortcut: false,
      myTasks: [] as Task[],
      firstTaskTime: "",
      loading: false,
      tasksAreLeft: true,
      allTasks: [] as Task[],
      allDone: false,
      myFinishedTasks: [] as Task[],
      finishedTasks: false,
      teamPlans: [] as Task[],
      plans: false,
      selectedMember: null as string | null,
      teamTasks: [] as Task[],
      snackbar: false,
      message: "",
      activeTask: [] as Task[],
      showDetails: false,
      teamMembersNames: [] as string[],
      newTaskNotes: "",
      selectedCategory: "",
      selectedProject: "",
      teamProjects: [] as Project[],
      isSelected: false,
      categories: [] as string[],
      shortcuts: [] as Shortcut[],
    };
  },
  computed: {
    isThursday(): boolean {
      const Thursday = 4;
      return getDay(new Date()) === Thursday;
    },
    isFriday(): boolean {
      const Friday = 5;
      return getDay(new Date()) === Friday;
    },
    isEndOfMonth(): boolean {
      const lastDayInMonth: Date = endOfMonth(new Date());
      const dayOfYear = getDayOfYear(new Date());
      return dayOfYear === getDayOfYear(lastDayInMonth);
    },
    token(): string | null {
      return this.userStore.token;
    },
    planExists(): boolean {
      return this.myTasks.some((task) => task.isPlan());
    },
    standupExists(): boolean {
      return this.myTasks.some((task) => task.isStandup());
    },
  },
  async created() {
    console.log(NetworkService.prototype);
    if (!this.userStore.token) {
      this.userStore.getTokenFromCookies();
      this.userStore.setToken(this.token);
    }
    if (!this.token) {
      this.$router.push("login");
      return;
    }
    await JSONFetcher.fetchJSONFile();
    //console.log(this.token);
    document.addEventListener("visibilitychange", this.forceUpdate, true);
    setInterval(this.fetches, minutesToMilliseconds(2));
    await this.forceUpdate();
  },
  methods: {
    async visibilityFetch() {
      this.loading = true;
      await this.fetches();
      this.loading = false;
    },
    taskExists(project: string, category: string): boolean {
      return this.myTasks.some((task) => task.checkTask(project, category));
    },
    async getShortcuts() {
      const response = await $network.index();
      const shortcutsObj: ShortcutInterface[] = response.data.data.items;
      const shortcuts = shortcutsObj.map((shortcut) => new Shortcut(shortcut));
      shortcuts.sort(this.customShortcutsSort);
      this.shortcuts = shortcuts;
    },
    customShortcutsSort(prev: Shortcut, curr: Shortcut) {
      const result =
        prev.project < curr.project && prev.category < curr.category;
      if (result) {
        return -1;
      }
      return 1;
    },
    setShortcut(value: boolean) {
      this.shortcut = value;
    },
    async fetches() {
      this.allTasks = [];
      const promises = [
        this.getMyTasks(),
        this.getTeamMembersNames(),
        this.getSpecificTasks(0),
        this.updateUserStore(),
      ];
      await Promise.all(promises);
      this.getFirstTaskTime();
      await this.getShortcuts();
    },
    async getSpecificTasks(offset: number) {
      const params = {
        offset: offset,
        limit: 12,
      };
      const response = await $network.get_users_tasks(params);
      const addedTasksObj: TaskInterface[] = response.data.data.items;
      const addedTasks = addedTasksObj.map((task) => new Task(task));
      if (addedTasks.length < 12) this.tasksAreLeft = false;
      if (offset) this.allTasks = this.allTasks.concat(addedTasks);
      else this.allTasks = addedTasks;
    },
    showMoreTasks() {
      this.getSpecificTasks(this.allTasks.length);
    },
    showAllTasks(value: boolean) {
      this.finishedTasks = value;
    },
    openPlansDialog() {
      this.plans = true;
    },
    selectMember(member: number) {
      this.selectedMember =
        member !== undefined ? this.teamMembersNames[member] : null;
    },
    setToken() {
      this.userStore.getTokenFromCookies();
    },
    getTotalWorkTimeMember(name: string) {
      // TODO: learn reduce/inject?
      const tasks = this.teamMemberTasks(name);
      const taskTimes = tasks.map((task) => task.totalTime());
      const totalTime = taskTimes.reduce((prev, curr) => prev + curr);
      return totalTime * 1000;
    },
    getFirstTaskTime() {
      ////console.log(this.myTasks)
      const dates = this.myTasks.map((task) => task.firstLog.start_date);
      if (!dates.length) return 0;
      dates.sort();
      this.firstTaskTime = dates[0];
    },
    getTotalSpentTime() {
      const taskTimes = this.myTasks.map((task) => task.totalTime());
      if (!taskTimes.length) return 0;
      const totalTime = taskTimes.reduce((prev, curr) => prev + curr);
      return totalTime * 1000;
    },
    resetSnackBar() {
      this.snackbar = false;
    },
    display(text: string) {
      this.message = text;
      this.snackbar = true;
      //this.snackbar = false
    },
    getLastLog() {
      if (!this.myTasks.length || !this.allTasksPaused()) {
        return 0;
      }

      const dates = this.myTasks.map((task) => this.convertDate(task));
      dates.sort();
      dates.reverse();
      const endDate = dates[0];
      const timeNow = new Date().getTime();
      const timeSinceEndDate = new Date(endDate).getTime();
      return timeNow - timeSinceEndDate;
    },
    convertDate(task: Task) {
      const endDate = task.lastLog.end_date;
      if (!endDate) return task.lastLog.start_date; //if there is no end date it means it was restarted
      return endDate;
    },
    createTaskPromise(task: Task) {
      const data = {
        id: task.id,
      };
      return TaskService.endTask(data);
    },
    async createTask(project: string, category: string) {
      const data = {
        project: project,
        category: category,
        due_date: "15/02/2022 09:00",
        estimated_time_hours: 0,
        estimated_time_minutes: 0,
        notes: this.newTaskNotes,
        limit: 5,
        offset: 0,
        running: 1,
      };
      await $network.create_task(data);
      if (this.activeTask.length) {
        await this.pauseActiveTask();
      }
      await this.getMyTasks();
      await this.getShortcuts();
      const text = "Task has been created";
      this.display(text);
    },
    async endAllTasks() {
      // 1. create a map of promises
      const tasks = this.myTasks;
      const promises = tasks.map(this.createTaskPromise);
      // 2. learn about await Promise.all([])
      await Promise.all(promises);
      await this.getMyTasks();
      const text = "All tasks have ended";
      this.display(text);
    },
    async createCustomTask(project: string, category: string) {
      const data = {
        project: project,
        category: category,
        due_date: "15/02/2022 09:00",
        estimated_time_hours: 0,
        estimated_time_minutes: 0,
        notes: this.newTaskNotes,
        limit: 5,
        offset: 0,
        running: 1,
      };
      await $network.create_task(data);
      await this.pauseActiveTask();
      await this.getMyTasks();
      const text = category + " in progress";
      this.display(text);
    },
    async pauseActiveTask() {
      const activeTask = this.activeTask.length ? this.activeTask[0] : null;
      if (!activeTask) return;
      const data = {
        id: activeTask.id,
      };
      await $network.stop_task(data);
    },
    signOut() {
      this.userStore.deleteTokenFromCookies();
      this.$router.push("login");
    },
    async forceUpdate() {
      this.loading = true;
      await this.fetches();
      this.loading = false;
    },
    selectShowDetails(value: boolean) {
      this.showDetails = value;
    },
    teamMemberTasks(name: string) {
      const memberTasks = this.teamTasks.filter((task) => task.belongsTo(name));
      // use map
      return memberTasks;
    },
    async getTeamMembersNames() {
      const params = {
        limit: 100,
        offset: 0,
      };
      const response = await $network.get_team_tasks(params);
      const teamTasksObj: TaskInterface[] = response.data.data.items;
      const teamTasks = teamTasksObj.map((task) => new Task(task));
      console.log(teamTasks);
      const team_members_names: Set<string> = new Set();
      ////console.log("teamTasks",teamTasks)
      const activeTeamTasks: Task[] = teamTasks.filter(
        (task) => !task.isFinished() && task.endedToday()
      );
      this.teamTasks = activeTeamTasks;
      this.getTeamPlans();
      for (const task of activeTeamTasks) {
        team_members_names.add(task.fullName());
      }
      this.teamMembersNames = Array.from(team_members_names);
    },
    async updateUserStore() {
      const response = await $network.get_user_info();
      const userObj = response.data.data;
      // repetition
      const user = new User(userObj);
      this.userStore.setUser(user);
    },
    getMyPlan() {
      const myPlan = this.myTasks.find((task) => task.isPlan());
      return myPlan;
    },
    async getMyTasks() {
      const response = await $network.get_users_tasks();
      const tasksObj: TaskInterface[] = response.data.data.items;
      const tasks: Task[] = tasksObj.map((task) => new Task(task));
      this.myFinishedTasks = tasks.filter((task) => task.isFinished());
      this.activeTask = tasks.filter((task) => task.isStarted());
      this.myTasks = tasks.filter((task) => task.endedToday());
      this.allDone = this.myTasks.every((task) => task.isFinished());
      this.getTeamPlans();
    },
    getTeamPlans() {
      const teamTasks = this.teamTasks;
      const teamPlans = teamTasks.filter((task) => task.isPlan());
      const myPlan = this.getMyPlan();
      if (myPlan) teamPlans.unshift(myPlan);
      this.teamPlans = teamPlans;
    },
    oldTask(task: Task) {
      return !task.endedToday();
    },
    allTasksPaused() {
      // "1" === 1
      if (!this.myTasks.length) return false;
      const noStartedTasks = this.myTasks.every((task) => !task.isStarted());
      const atLeastOnePaused = this.myTasks.some((task) => task.isPaused());
      return atLeastOnePaused && noStartedTasks;
    },
    getTotalBreakTime() {
      if (!this.myTasks.length) return 0;
      this.getFirstTaskTime();
      const timeSinceFirstTask = new Date(this.firstTaskTime).getTime();
      const totalBreak =
        new Date().getTime() - timeSinceFirstTask - this.getTotalSpentTime();
      // //console.log(totalBreak)
      return totalBreak;
    },
    formattedElapsedTime(elapsedTime: number) {
      const date = new Date(0);
      date.setSeconds(elapsedTime / 1000);
      const utc = date.toUTCString();
      return utc.substr(utc.indexOf(":") - 2, 8);
    },
    async getTeamProjects() {
      const response = await $network.get_team_projects();
      const projectsObj: ProjectInterface[] = response.data.data.items;
      const projects = projectsObj.map((project) => new Project(project));
      this.teamProjects = projects;
    },
  },
  watch: {
    finishedTasks(newValue) {
      if (!newValue) {
        this.allTasks = [];
        this.tasksAreLeft = true;
        this.getSpecificTasks(0);
      }
    },
  },
});
