import ProjectInterface from "@/helpers/interfaces/ProjectInterface";
import Category from "@/helpers/presenters/Category";

export default class Project {
  id: number;
  name: string;
  categories: Category[];
  constructor(project: ProjectInterface) {
    this.id = project.id;
    this.name = project.name;
    this.categories = project.task_categories.map(
      (category) => new Category(category)
    );
  }
}
