<template>
  <div class="loader">
    <img class="image" id="loader-logo" src="@/assets/sowlutions-logo.png" />
    <img class="image" id="loader-img" src="@/assets/spinner.gif" />
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Loader",
  methods: {
    signOut() {
      this.$emit("signOut");
    },
  },
});
</script>

<style scoped>
.loader {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(101, 141, 199, 0.43);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(40, 118, 234);
}

.image {
  width: 11rem;
  height: 11rem;
}

#loader-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#loader-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 11rem));
}
</style>
