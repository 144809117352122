import Vue from "vue";
import axios, { AxiosStatic } from "axios";

Vue.use({
  install() {
    Vue.prototype.axios = axios.create({});
  },
});

declare module "vue/types/vue" {
  interface Vue {
    axios: AxiosStatic;
  }
}
