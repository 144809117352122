import UserInterface from "@/helpers/interfaces/UserInterface";

export default class User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  authToken: string | undefined;

  constructor(user: UserInterface) {
    this.id = user.id;
    this.email = user.email;
    this.firstName = user.first_name;
    this.lastName = user.last_name;
    this.authToken = user.auth_token;
  }

  setFullName(firstName: string, lastName: string) {
    this.firstName = firstName;
    this.lastName = lastName;
  }

  getFullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}
