import { defineComponent } from "@vue/composition-api";
import TaskBox from "@/components/TaskBox/TaskBox.vue";
import Task from "@/helpers/presenters/Task";
export default defineComponent({
  name: "SelectedTasks",
  data() {
    return {
      currentIndex: 0,
    };
  },
  methods: {
    incrementPage() {
      this.currentIndex += 4;
      console.log(this.name, this.tasks);
    },
    decrementPage() {
      this.currentIndex -= 4;
    },
  },
  computed: {
    page(): number {
      return Math.floor(this.currentIndex / 4) + 1;
    },
    totalPages(): number {
      const tasks = <Task[]>this.tasks;
      return Math.ceil(tasks.length / 4);
    },
  },
  props: {
    activeTask: Object,
    tasks: Array,
    name: String,
  },
  watch: {
    tasks() {
      this.currentIndex = 0;
      console.log(this.name, this.tasks);
    },
  },
  components: {
    TaskBox,
  },
});
