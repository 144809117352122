import { defineComponent } from "@vue/composition-api";
import useUserStore from "@/stores/userStore";
import AuthenticationService from "@/network/api/AuthenticationService";

export default defineComponent({
  name: "AppBar",
  setup() {
    const userStore = useUserStore();
    return {
      userStore,
    };
  },
  data() {
    return {
      edit: false,
      alert: false,
      firstName: "",
      lastName: "",
      firstNameClicked: false,
      lastNameClicked: false,
    };
  },
  methods: {
    signOut(): void {
      this.$emit("signOut");
    },
    clickEdit(): void {
      this.edit = !this.edit;
    },
    async save(): Promise<void> {
      if (this.firstNameClicked || this.lastNameClicked) {
        this.alert = true;
        return;
      }
      const data = {
        first_name: this.firstName,
        last_name: this.lastName,
      };
      this.alert = false;
      await (window as any).$network.update_user_info(data);
      if (this.userStore.user)
        this.userStore.user.setFullName(this.firstName, this.lastName);
      const text = "Changes have been saved.";
      this.$emit("display", text);
    },
    reset(): void {
      this.alert = false;
      this.firstNameClicked = false;
      this.lastNameClicked = false;
      if (this.userStore.user) this.firstName = this.userStore.user.firstName;
      if (this.userStore.user) this.lastName = this.userStore.user.lastName;
    },
  },
  computed: {
    getFullName(): string {
      if (!this.userStore.user) return "Fetching...";
      return this.userStore.user.getFullName();
    },
  },
  watch: {
    edit(newValue): void {
      if (newValue) this.reset();
    },
  },
});
