import isToday from "date-fns/isToday";
import format from "date-fns/format";
import User from "@/helpers/presenters/User";
import { TaskLog, TaskInterface } from "@/helpers/interfaces/TaskInterface";

const STATES = {
  FINISHED: "finished",
  STARTED: "started",
  PAUSED: "paused",
};

export default class Task {
  id: number;
  totalSpentTime: number;
  notes: string;
  status: string;
  project: string;
  category: string;
  firstLog: TaskLog;
  lastLog: TaskLog;
  user: User;
  constructor(task: TaskInterface) {
    this.id = task.id;
    this.totalSpentTime = parseInt(task.total_spent_time);
    this.notes = task.notes;
    this.status = task.status;
    this.project = task.project.name;
    this.category = task.task_category.name;
    this.firstLog = task.first_log;
    this.lastLog = task.last_log;
    this.user = new User(task.user);
  }

  isFinished() {
    return this.status === STATES.FINISHED;
  }

  isStarted() {
    return this.status === STATES.STARTED;
  }

  isPaused() {
    return this.status === STATES.PAUSED;
  }

  getTimeFromDate(dateString: string) {
    return new Date(dateString).getTime();
  }

  currentTime() {
    return new Date().getTime();
  }

  timeSince(time: number) {
    return this.currentTime() - time;
  }

  activeTaskTime() {
    return this.timeSince(this.getTimeFromDate(this.lastLog.start_date)) / 1000;
  }

  totalTime() {
    let total = this.totalSpentTime;
    if (this.isStarted()) total += this.activeTaskTime();
    return total;
  }

  startedToday() {
    return isToday(new Date(this.lastLog.start_date));
  }

  endedToday() {
    if (!this.lastLog?.end_date) return true;
    return isToday(new Date(this.lastLog.end_date));
  }

  fullName() {
    return this.user.getFullName();
  }

  checkTask(project: string, category: string) {
    // console.log(
    //   this.project,
    //   project,
    //   "and",
    //   this.category,
    //   category,
    //   this.project === project && this.category === category
    // );
    return this.project === project && this.category === category;
  }

  isValid() {
    const lastLogTime = this.getTimeFromDate(this.lastLog.end_date);
    return true;
  }

  isPlan() {
    return this.checkTask("Internal", "Planning");
  }

  isStandup() {
    return this.checkTask("Internal", "Standup");
  }

  belongsTo(name: string) {
    return this.fullName() === name && !this.isFinished() && this.endedToday();
  }

  getDate() {
    const taskDate = new Date(this.lastLog.end_date);
    const formattedTaskDate = format(taskDate, "MMMM d yyyy");
    return formattedTaskDate;
  }
}
