import { defineComponent } from "@vue/composition-api";
import TaskBox from "@/components/TaskBox/TaskBox.vue";
import StopWatch from "@/components/StopWatch/StopWatch.vue";
import useUserStore from "@/stores/userStore";
import Task from "@/helpers/presenters/Task";
import { PropType } from "vue";

export default defineComponent({
  name: "ActiveMembers",
  data() {
    return {
      showDetails: false,
      selectedMember: null,
    };
  },
  setup() {
    const userStore = useUserStore();
    return {
      userStore,
    };
  },
  methods: {
    openPlansDialog() {
      this.$emit("openPlansDialog");
    },
    selectMember(member: string) {
      this.$emit("selectMember", member);
    },
    forceUpdate() {
      this.$emit("forceUpdate");
    },
    selectShowDetails(value: boolean) {
      this.showDetails = value;
      this.$emit("selectShowDetails", this.showDetails);
    },

    isToday(date: Date) {
      const today = new Date();

      // 👇️ Today's date

      if (
        today.getFullYear() === date.getFullYear() &&
        today.getMonth() === date.getMonth() &&
        today.getDate() === date.getDate()
      ) {
        return true;
      }

      return false;
    },
    getTotalWorkTimeMember(name: string): number {
      // TODO: learn reduce/inject?
      const tasks: Task[] = this.teamMemberTasks(name);
      const taskTimes: number[] = tasks.map((task) => task.totalTime());
      const totalTime = taskTimes.reduce((prev, curr) => prev + curr);
      return totalTime * 1000;
    },
    teamMemberTasks(name: string) {
      // const memberTasks: any = <Task[]>(
      //   this.teamTasks.filter((task: Task) => task.belongsTo(name))
      // );
      let memberTasks = <Task[]>this.teamTasks;
      memberTasks = memberTasks.filter((task) => task.belongsTo(name));
      // use map
      return memberTasks;
    },
    latestTask(memberName: string): Task {
      ////console.log("teamMemberTasks of ",memberName, this.teamMemberTasks(memberName))
      return this.teamMemberTasks(memberName)[0];
    },
  },
  computed: {
    token(): string | object | null {
      return this.userStore.token;
    },
  },
  props: {
    teamTasks: {
      type: Array,
      required: true,
    },
    teamMembersNames: {
      type: Array,
      required: true,
    },
  },
  components: {
    TaskBox,
    StopWatch,
  },
  watch: {
    teamTasks(): void {
      this.teamMemberTasks;
    },
    teamMembersNames(): void {
      this.$emit("selectMember", this.selectedMember);
    },
  },
});
