/* eslint-disable @typescript-eslint/ban-ts-comment */
import { defineComponent } from "@vue/composition-api";
import useUserStore from "@/stores/userStore";
import TaskService from "@/network/api/TaskService";
import TeamService from "@/network/api/TeamService";
import Project from "@/helpers/presenters/Project";
import ProjectInterface from "@/helpers/interfaces/ProjectInterface";
import JSONFetcher from "@/network/api/JSONFetcher";

export default defineComponent({
  name: "MemberTasks",
  setup() {
    const userStore = useUserStore();
    return {
      userStore,
    };
  },
  async created() {
    await JSONFetcher.fetchJSONFile();
    this.token = this.userStore.token;
    await this.getTeamProjects();
  },
  data() {
    return {
      isValid: true,
      newTaskNotes: "",
      selectedProject: "",
      selectedCategory: "",
      categories: [] as string[],
      teamProjects: [] as Project[],
      isSelected: false,
      token: null as string | object | null,
      isClicked: false,
      selectRules: [(v: string) => !!v || "Value is required"],
    };
  },
  methods: {
    validate() {
      //@ts-ignore
      this.$refs.form.validate();
      if (this.isValid) this.createTask();
    },
    async getTeamProjects() {
      console.log((window as any).$network);
      const response = await (window as any).$network.get_team_projects();
      const projectsObj: ProjectInterface[] = response.data.data;
      const projects = projectsObj.map((project) => new Project(project));
      this.teamProjects = projects;
    },
    async createTask() {
      this.isClicked = true;
      const data = {
        project: this.selectedProject,
        category: this.selectedCategory,
        due_date: "15/02/2022 09:00",
        estimated_time_hours: 0,
        estimated_time_minutes: 0,
        notes: this.newTaskNotes,
        limit: 5,
        offset: 0,
        running: 1,
      };
      await (window as any).$network.create_task(data);
      if (this.activeTask.length) {
        await this.pauseActiveTask();
      }
      this.isClicked = false;
      this.$emit("getMyTasks");
      const text = "Task has been created";
      this.$emit("display", text);
      this.clearForm();
    },
    clearForm() {
      //@ts-ignore
      this.$refs.form.reset();
    },
    async pauseActiveTask() {
      const activeTask: any = this.activeTask.length
        ? this.activeTask[0]
        : null;
      if (!activeTask) return;
      const data = {
        id: activeTask.id,
      };
      await (window as any).$network.stop_task(data);
    },
    selectProject() {
      this.isSelected = true;
      const selectedProject = this.selectedProject;
      const projects = this.teamProjects;
      const selectedProjectObject: Project | undefined = projects.find(
        (project) => project.name === selectedProject
      );
      if (!selectedProjectObject) return;
      const selectedCategoriesObject = selectedProjectObject.categories;
      this.categories = selectedCategoriesObject.map(
        (category) => category.name
      );
    },
  },
  props: {
    activeTask: {
      type: Array,
      required: true,
    },
  },
  computed: {
    getTeamProjectsNames(): string[] {
      const projects = this.teamProjects;
      if (!projects) return [];
      return projects.map((project) => project.name);
    },
  },
});
