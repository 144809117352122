<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import Vue from "vue";
import NetworkService from "@/network/api/NetworkService";
import JSONFetcher from "@/network/api/JSONFetcher.js";

export default Vue.extend({
  name: "App",
  created() {
    this.fetchAPICalls();
  },
  data: () => ({
    //
  }),
  methods: {
    async fetchAPICalls() {
      const wrappedMethods = await JSONFetcher.fetchJSONFile();
      window.$network = new NetworkService(wrappedMethods);
      console.log("updated window");
    },
  },
});
</script>

<style>
#app {
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.v-textarea textarea {
  line-height: 1.25rem !important;
  font-size: 0.8rem;
}

#app h1 {
  font-family: "Poppins", sans-serif !important;
}
</style>
