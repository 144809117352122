import ShortcutInterface from "@/helpers/interfaces/ShortcutInterface";
import Shortcut from "@/helpers/presenters/Shortcut";
import { APIService } from "@/network/api/APIService";
import { id } from "@/network/api/TaskService";

class UserShortcutsService extends APIService {
  constructor() {
    super();
  }
  async addShortcut(data: object) {
    const params = {
      shortcut_type: "task_start",
      data: data,
    };
    const response = await this.post("/user_shortcuts", params);
    return response;
  }
  async getShortcuts() {
    const response = await this.get("/user_shortcuts");
    const shortcuts: ShortcutInterface[] = response.data.data.items;
    return shortcuts.map((shortcut) => new Shortcut(shortcut));
  }
  async deleteShortcut(data: id) {
    const response = await this.delete(`/user_shortcuts/${data.id}`, data);
    return response;
  }
}

export default new UserShortcutsService();
